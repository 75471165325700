import {
  LandingPage,
  Column,
  Typography,
  ImageSection,
  Box,
  Button,
} from '@oresundsbron/design-system';
import { useTranslation } from 'next-i18next';
import { SEOMetadata } from '../components/SEOMetadata';
import Image from 'next/image';
import { useSEOMetadata } from '../hooks/useSEOMetaData';
import { useLinks } from '../hooks/useLinks';
import { useMemo } from 'react';
import { pipe } from 'fp-ts/lib/function';
import { toUndefined } from 'fp-ts/lib/Option';
import { DefaultLink } from '~/components/Links/DefaultLink';
import { useNavigation } from '~/hooks/useNavigation';
import { isNavigationLink } from '../lib/navigation';

const IMAGE1 = '/images/customer-service.png';

const NotFound = () => {
  const { t } = useTranslation(['error', 'common'], {
    nsMode: 'fallback',
  });

  const { findLinkByCodeRef, getLink } = useLinks();
  const customerSupportLink = useMemo(
    () => pipe(findLinkByCodeRef('customer_support'), toUndefined),
    [findLinkByCodeRef]
  );

  // I use header navigation function to get language specific /private/-URL as it is currently not avaiable through findLinkByCodeRef()
  const nav = useNavigation('Header');
  const privateLink = useMemo(() => {
    let sec = nav?.sections ?? [];
    return sec
      .filter(isNavigationLink)
      .map(({ title, pageLink }) => ({
        title,
        link: getLink(pageLink.sys.id),
      }))
      .filter(({ link }) => !!link)[0];
  }, [nav?.sections, getLink]);

  const seoMetadata = useSEOMetadata(
    [],
    [
      { name: 'seo-title', content: `${t('notFound.title')}` },
      {
        name: 'seo-description',
        content: `${t('notFound.description')}${t('notFound.homePage')}`,
      },
    ]
  );

  return (
    <>
      <SEOMetadata items={seoMetadata} />
      <LandingPage title="">
        <Column
          as="section"
          column={{ xs: '2/-2', md: '3/-3' }}
          display="flex"
          py="spacing.800"
          sx={{
            justifyContent: 'center',
            flexDirection: 'column',
            textAlign: 'center',
          }}
        >
          <Typography
            variant="h2"
            as="h1"
            sx={{
              fontWeight: 'fontWeights.light',
              lineHeight: 'lineHeights.400',
            }}
          >
            {t('notFound.title')}
          </Typography>
          <Typography>
            {t('notFound.description')}
            <DefaultLink
              sx={{ color: 'colors.primary.600' }}
              href={privateLink?.link?.path ?? '/'}
            >
              {t('notFound.homePage')}
            </DefaultLink>
          </Typography>
        </Column>
        <Column column="1/-1" as="section">
          <ImageSection
            image={
              <Image
                alt="IMAGE1"
                src={IMAGE1}
                sizes="(max-width: 768px) 50vw,
                (max-width: 1200px) 25vw,
                25vw"
                fill
                style={{
                  aspectRatio: '16/9',
                  objectFit: 'cover',
                }}
                unoptimized
              />
            }
          >
            <Box
              display="flex"
              py="spacing.800"
              sx={{
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'colors.primary.600',
              }}
            >
              <Box
                sx={{ maxWidth: '65ch', px: 'spacing.500', pb: 'spacing.400' }}
              >
                <Typography variant="h3" sx={{ color: 'colors.white' }}>
                  {t('customerSupport.title')}
                </Typography>
                <Typography
                  variant="paragraph"
                  sx={{ py: 'spacing.400', color: 'colors.white' }}
                >
                  {t('customerSupport.description')}
                </Typography>
                <div>
                  <Button
                    as={DefaultLink}
                    href={customerSupportLink?.path}
                    variant="outlined"
                    mt="spacing.400"
                    sx={{
                      color: 'colors.white',
                      borderColor: 'colors.white',
                      width: 'fit-content',
                    }}
                  >
                    {t('customerSupport.button')}
                  </Button>
                </div>
              </Box>
            </Box>
          </ImageSection>
        </Column>
      </LandingPage>
    </>
  );
};

export default NotFound;
